<template>
  <div class="card">
    <div class="card-header">
      <h4>All Players</h4>
      <div class="card-header-form">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search" v-model="seek" @keydown.enter="search">
          <div class="input-group-btn">
            <button class="btn btn-primary" @click.prevent="search"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <app-loader v-if="loadingPlayers"/>
      <div class="row" v-else>
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th>ID</th>
                <th>Photo</th>
                <th>Name</th>
                <th>Age</th>
                <th class="text-center">Action</th>
              </tr>
              <tr v-for="(player, index) in players.data" :key="index">

                <td>P-{{ getZeros(player.id) + '' + player.id }}</td>
                <td class="align-middle">
                  <img alt="image" :src="$store.state.Global.rootCDN + player.photo" class="rounded-circle" width="35"
                       data-toggle="tooltip"
                       title="" data-original-title="Wildan Ahdian">

                </td>

                <td>
                  {{ player.en_first_name + ' ' + player.en_last_name }}
                </td>

                <td>{{ moment(player.dob).fromNow() }}</td>

                <td class="text-center">

                  <a href="#" class="btn btn-primary btn-sm m-1" @click.prevent="setPlayer(player)">Detail</a>
                  <a href="#" class="btn btn-dark btn-sm m-1" @click.prevent="">Profile</a>
                  <a href="#" class="btn btn-danger btn-sm m-1" @click.prevent="destroy(player.id)">
                    Remove
                  </a>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="col-12 text-right m-3 pr-5">
          Showing {{ players.to }} from {{ players.total }} Records
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <li class="page-item" :class="{'disabled': players.current_page === 1}">
                <a class="page-link" href="#" tabindex="-1"
                   @click.prevent="show(players.current_page -1)">
                  <i class="fas fa-chevron-left"></i>
                </a>
              </li>

              <li class="page-item"
                  :class="{'disabled': players.current_page === players.last_page}">
                <a class="page-link" href="#" @click.prevent="show(players.current_page + 1)"><i
                    class="fas fa-chevron-right"></i></a>
              </li>
            </ul>
          </nav>
        </div>

      </div>


    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {destroy, show} from '@/services/managePlayers/Players';
import {useToast} from "vue-toastification";
import Loader from "@/components/layouts/parts/Loader";

export default {
  props: ['players', 'loadingPlayers'],
  data() {
    return {
      toast: useToast(),
      seek: '',
    }
  },
  components: {
    appLoader: Loader,
  },

  methods: {

    getZeros(id) {
      if (id.toString().length === 1) {
        return '000';
      }
      if (id.toString().length === 2) {
        return '00';
      }
      if (id.toString().length === 3) {
        return '0';
      }
      if (id.toString().length === 4) {
        return '';
      }
    },
    show(id) {

      show(id).then(response => {
        this.$emit('show', response.data);
      });
    },
    destroy(id) {

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.info('deleting record!');

          destroy(id).then(() => {

            this.toast.clear();
            this.$swal(
                'Deleted!',

                'Record has been deleted.',

                'success'
            );

            this.show(this.players.current_page);

          }, error => {

            this.toast.clear();
            this.toast.error(error.response.data.message)

          });


        }

      });


    },

    setPlayer(player) {
      this.$emit('setPlayer', player);
    },

    search() {
      this.$emit('search', this.seek);
    },
    moment(date) {


      return moment(date);


    }
  }
}
</script>
<style>

</style>