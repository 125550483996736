<template>
  <div class="card">
    <div class="card-header">
      <h4>
        New Player
      </h4>
      <button class="btn btn-warning" style="position: absolute; right: 10px;" @click.prevent="toggleForm">
        <i class="fa fa-angle-down" v-if="!showForm"></i>
        <i class="fa fa-angle-up" v-else></i>
      </button>
    </div>

    <div class="card-body">
      <transition enter-active-class="animate__animated animate__fadeIn">

        <div v-if="showForm">
          <form action="#" method="post" enctype="multipart/form-data" @submit.prevent="store" id="playerForm">
            <fieldset class="fieldset">
              <legend>Personal Information</legend>

              <div class="row">
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="photo">Photo</label>
                    <div class="photo" :class="{'photo-height': setPlayer.photo}"
                         :style="{'background-image': 'url('+  ( $store.state.Global.rootCDN + setPlayer.photo ) +')'}">

                    </div>
                    <input type="file" class="form-control" name="photo" id="photo">
                    <input type="hidden" class="form-control" name="old_photo" v-if="setPlayer.id"
                           :value="setPlayer.photo">

                    <span class="text text-danger" v-if="validationErrors.photo">
                      {{ validationErrors.photo[0] }}
                    </span>

                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="passport">Passport</label>
                    <div class="photo" :class="{'photo-height': setPlayer.passport}"
                         :style="{'background-image': 'url('+ ($store.state.Global.rootCDN + setPlayer.passport)+')'}">

                    </div>
                    <input type="file" class="form-control" name="passport" id="passport">
                    <input type="hidden" class="form-control" name="old_passport" v-if="setPlayer.id"
                           :value="setPlayer.passport">
                    <span class="text text-danger" v-if="validationErrors.passport">
                      {{ validationErrors.passport[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="nic">NIC</label>
                    <div class="photo" :class="{'photo-height': setPlayer.nic}"
                         :style="{'background-image': 'url('+ ($store.state.Global.rootCDN + setPlayer.nic ) +')'}">

                    </div>
                    <input type="file" class="form-control" name="nic" id="nic">
                    <input type="hidden" class="form-control" name="old_nic" v-if="setPlayer.id"
                           :value="setPlayer.nic">
                    <span class="text text-danger" v-if="validationErrors.nic">
                      {{ validationErrors.nic[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row text-right" style="direction: rtl;">


                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>نوم</label>
                    <input type="text" name="pa_first_name" class="form-control" :value="setPlayer.pa_first_name">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>تخلص یا کورنی نوم</label>
                    <input type="text" name="pa_last_name" class="form-control" :value="setPlayer.pa_last_name">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>د پلار نوم</label>
                    <input type="text" name="pa_father_name" class="form-control" :value="setPlayer.pa_father_name">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>د نیکه نوم</label>
                    <input type="text" name="pa_grandfather_name" class="form-control"
                           :value="setPlayer.pa_grandfather_name">
                  </div>
                </div>

              </div>
              <hr>
              <div class="row ">


                <div class="col-12 col-md-3">
                  <div class="form-group">

                    <label for="name">First Name</label>


                    <input type="text" name="en_first_name" class="form-control"
                           :value="player.en_first_name"
                           id="name">

                    <input type="hidden" name="id" :value="setPlayer.id">

                    <span class="text text-danger" v-if="validationErrors.en_first_name">
                      {{ validationErrors.en_first_name[0] }}
                    </span>

                  </div>
                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" name="en_last_name" class="form-control" :value="setPlayer.en_last_name">
                    <span class="text text-danger" v-if="validationErrors.en_last_name">
                      {{ validationErrors.en_last_name[0] }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Father Name</label>
                    <input type="text" name="en_father_name" class="form-control" :value="setPlayer.en_father_name">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Grandfather Name</label>
                    <input type="text" name="en_grandfather_name" class="form-control"
                           :value="setPlayer.en_grandfather_name">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Passport No.</label>
                    <input type="text" name="passport_number" class="form-control" :value="setPlayer.passport_number">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>NIC No.</label>
                    <input type="text" name="nic_number" class="form-control" :value="setPlayer.nic_number">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Cell No.</label>
                    <input type="text" name="cell" class="form-control" :value="setPlayer.cell">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Home Phone</label>
                    <input type="text" name="home_phone" class="form-control" :value="setPlayer.home_phone">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" name="email" class="form-control" :value="setPlayer.email">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Blood Group</label>
                    <select name="blood_group_id" class="form-control">
                      <option v-for="(blood_group, index) in technicalInfo.blood_group" :value="blood_group.id"
                              :selected="blood_group.id === setPlayer.blood_group_id"
                              :key="index">
                        {{ blood_group.name }}
                      </option>

                    </select>
                    <span class="text text-danger" v-if="validationErrors.blood_group_id">
                      {{ validationErrors.blood_group_id[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Date of Birth</label>
                    <input type="date" name="date_of_birth" class="form-control" :value="setPlayer.dob">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Place of Birth</label>
                    <input type="text" name="place_of_birth" class="form-control" :value="setPlayer.pob">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Bank Account</label>
                    <input type="text" name="bank_account" class="form-control" :value="setPlayer.bank_account">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Native Language</label>
                    <input type="text" name="native_language" class="form-control" :value="setPlayer.native_language">
                  </div>
                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Weight</label>
                    <input type="text" name="weight" class="form-control" :value="setPlayer.weight">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Height</label>
                    <input type="text" name="height" class="form-control" :value="setPlayer.height">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label>Country</label>
                    <select name="country_id" class="form-control">
                      <option value="">--select--</option>

                      <option v-for="(country, index) in countries" :value="country.id" :key="index"
                              :selected="country.id === setPlayer.country_id">

                        {{ country.en_short_name }}
                      </option>
                    </select>
                    <span class="text text-danger" v-if="validationErrors.country_id">
                      {{ validationErrors.country_id[0] }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-md-3">

                  <div class="form-group">
                    <label>Playing for</label>
                    <select name="playing_for_country" class="form-control" @change="getPlayingForCountry"
                            id="playing_for_country">

                      <option value="">--select--</option>
                      <option v-for="(country, index) in countries" :value="country.id"
                              :selected="country.id === setPlayer.playing_for_country" :key="index">
                        {{ country.en_short_name }}
                      </option>

                    </select>
                    <span class="text text-danger" v-if="validationErrors.playing_for_country">
                      {{ validationErrors.playing_for_country[0] }}
                    </span>
                  </div>

                </div>

                <div class="col-12 col-md-3" v-if="playingForCountry[0] == 1 || setPlayer.playing_for_country === 1">
                  <div class="form-group">
                    <label>Team</label>
                    <select name="team_id" class="form-control">

                      <option value="0">--select--</option>
                      <option v-for="(team, index) in teams" :value="team.id" :key="index"
                              :selected="team.id === setPlayer.team_id"> {{ team.name }}
                      </option>

                    </select>
                    <span class="text text-danger" v-if="validationErrors.team_id">
                      {{ validationErrors.team_id[0] }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-md-3" v-if="playingForCountry[0] == 1 || setPlayer.playing_for_country === 1">
                  <div class="form-group">
                    <label>Grade</label>
                    <select name="grade" class="form-control">

                      <option value="0">--select--</option>

                      <option value="A" :selected="setPlayer.grade === 'A'">A</option>
                      <option value="B" :selected="setPlayer.grade === 'B'">B</option>
                      <option value="C" :selected="setPlayer.grade === 'C'">C</option>
                      <option value="D" :selected="setPlayer.grade === 'D'">D</option>
                      <option value="E" :selected="setPlayer.grade === 'E'">E</option>

                    </select>
                    <span class="text text-danger" v-if="validationErrors.grade">
                      {{ validationErrors.grade[0] }}
                    </span>
                  </div>
                </div>


              </div>

              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Gender</label>
                    <br>
                    <label class="custom-switch " style="display: inline;">
                      <input type="radio" name="gender" value="2" class="custom-switch-input"
                             :checked="setPlayer.gender == 2">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">Male</span>
                    </label>
                    <label class="custom-switch" style="display: inline;">
                      <input type="radio" name="gender" value="1" class="custom-switch-input"
                             :checked="setPlayer.gender == 1">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">Female</span>
                    </label>
                    <span class="text text-danger" v-if="validationErrors.gender">
                      {{ validationErrors.gender[0] }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Marital Status</label>
                    <br>
                    <label class="custom-switch " style="display: inline;">
                      <input type="radio" name="marital_status" value="2" class="custom-switch-input"
                             :checked="setPlayer.marital_status == 2">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">Single</span>
                    </label>
                    <label class="custom-switch" style="display: inline;">
                      <input type="radio" name="marital_status" value="1" class="custom-switch-input"
                             :checked="setPlayer.marital_status == 1">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">Married</span>
                    </label>
                    <span class="text text-danger" v-if="validationErrors.gender">
                      {{ validationErrors.gender[0] }}
                    </span>
                  </div>
                </div>

              </div>

            </fieldset>
            <br>
            <fieldset class="fieldset">
              <legend>Technical Information</legend>


              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Playing Roll</label>
                  <br>
                  <label class="custom-switch " style="display: inline;"
                         v-for="(playingRoll, index) in technicalInfo.playing_roll" :key="index">
                    <input type="checkbox" name="playing_roll_id" :value="playingRoll.id"
                           class="custom-switch-input" :checked="setPlayer.playing_roll_id === playingRoll.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ playingRoll.name }}</span>
                  </label>

                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Batting Style</label>
                  <br>
                  <label class="custom-switch " style="display: inline;"
                         v-for="(battingStyle, index) in technicalInfo.batting_style" :key="index">
                    <input type="checkbox" name="batting_style_id" :value="battingStyle.id"
                           class="custom-switch-input" :checked="setPlayer.batting_style_id === battingStyle.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ battingStyle.name }}</span>
                  </label>

                </div>
              </div>

              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Batting Order</label>
                  <br>
                  <label class="custom-switch " style="display: inline;"
                         v-for="(battingOrder, index) in technicalInfo.batting_order" :key="index">
                    <input type="checkbox" name="batting_order_id" :value="battingOrder.id"
                           class="custom-switch-input" :checked="setPlayer.batting_order_id === battingOrder.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ battingOrder.name }}</span>
                  </label>

                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Bowling Style</label>
                  <br>
                  <label class="custom-switch " style="display: inline;"
                         v-for="(bowlingStyle, index) in technicalInfo.bowling_style" :key="index">
                    <input type="checkbox" name="bowling_style_id" :value="bowlingStyle.id"
                           class="custom-switch-input" :checked="setPlayer.bowling_style_id === bowlingStyle.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ bowlingStyle.name }}</span>
                  </label>

                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Bowling Type</label>
                  <br>
                  <label class="custom-switch " style="display: inline;"
                         v-for="(bowlingType, index) in technicalInfo.bowling_type" :key="index">
                    <input type="checkbox" name="bowling_type_id" :value="bowlingType.id"
                           class="custom-switch-input" :checked="setPlayer.bowling_type_id === bowlingType.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ bowlingType.name }}</span>
                  </label>
                </div>

              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="bold-label">Other Skills</label>
                  <br>
                  <label class="custom-switch " style="display: inline;">
                    <input type="checkbox" name="wicket_keeper_id" :value="technicalInfo.wicket_keeper.id"
                           class="custom-switch-input"
                           :checked="setPlayer.wicket_keeper_id === technicalInfo.wicket_keeper.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ technicalInfo.wicket_keeper.name }}</span>
                  </label>

                  <label class="custom-switch " style="display: inline;">
                    <input type="checkbox" name="all_rounder_id" :value="technicalInfo.all_rounder.id"
                           class="custom-switch-input"
                           :checked="setPlayer.all_rounder_id === technicalInfo.all_rounder.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ technicalInfo.all_rounder.name }}</span>
                  </label>

                </div>
              </div>
            </fieldset>
            <div class="row mt-2">
              <div class="col-12">
                <button type="submit" class="btn btn-info float-right">Save</button>
                <button type="reset" class="btn btn-danger float-right mr-1" @click="clearForm">Reset</button>


              </div>
            </div>
          </form>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <p class="text text-center text-info">
              To Add New Player Please Click <a href="" @click.prevent="toggleForm">Here </a>
            </p>
          </div>
        </div>

      </transition>
    </div>
  </div>
</template>
<script>
import {getTechnicalInfo, show, store} from "@/services/managePlayers/Players";
import {useToast} from "vue-toastification";


export default {

  props: ['setPlayer', 'showForm', 'countries', 'teams'],

  data() {
    return {
      player: this.setPlayer,
      playingForCountry: [0],
      photo: this.$store.state.Global.rootCDN + 'images/photo-icon.jpg',
      passport: this.$store.state.Global.rootCDN + 'images/photo-icon.jpg',
      nic: this.$store.state.Global.rootCDN + 'images/photo-icon.jpg',
      technicalInfo: {},
      toast: useToast(),
      validationErrors: {},
      players: {},


    };
  },


  methods: {


    show(page = 1) {

      show(page).then(response => {

        this.playsers = response.data;
        this.$emit('show', response.data);

      }, error => {
        this.setError(error);
      });

    },

    setError(error) {

      this.toast.clear();

      if (error.response.status === 422) {

        this.validationErrors = error.response.data.errors;

        this.toast.error(error.response.data.message);

      } else {

        this.toast.error(error.response.data.message)
      }
    }
    ,

    clearForm() {


      this.$emit('emptyPlayerObject');
      this.toggleForm();

    },

    getPlayingForCountry() {

      if (document.getElementById('playing_for_country')) {
        this.playingForCountry[0] = document.getElementById('playing_for_country').value;
      }

      this.$emit('playingCountryChange', this.playingForCountry[0]);

    },

    store() {
      this.toast.info('processing...')
      let form = document.getElementById('playerForm')
      let data = new FormData(form);
      store(data).then(() => {
        this.toast.clear();

        this.clearForm();

        this.show(this.players.current_page);

        this.toast.info('New Player has been added successfully');


      }, error => {
        this.setError(error)
      });
    }
    ,

    onPhotoChange(e) {
      const file = e.target.files[0];
      this.photo = URL.createObjectURL(file);
      return false;
    },
    onPassportChange(e) {
      const file = e.target.files[0];
      this.passport = URL.createObjectURL(file);
    }
    ,
    onNicChange(e) {
      const file = e.target.files[0];
      this.nic = URL.createObjectURL(file);
    }
    ,

    toggleForm() {

      if (this.showForm) {
        this.$emit('emptyPlayerObject');
      }


      this.$emit('toggleForm');

    }
    ,
    getTechnicalInfo() {
      getTechnicalInfo().then(response => {
        this.technicalInfo = response.data;
      });
    }
  }
  ,

  mounted() {
    this.getTechnicalInfo();
    this.show();
    // this.getPlayingForCountry();
  }
}
</script>

<style scoped>
.photo {
  width: 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.photo-height {
  height: 200px;
}

.photo img {
  width: 100%;
}

.bold-label {
  font-size: 14px;
  font-weight: bold;
}

</style>