<template>

  <div class="section mb-5">

    <head-slot>
      <title>Scoring And Performance | Manage Players</title>
    </head-slot>

    <div class="section-header">

      <h1>Scoring and Performance</h1>

      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="#" @click.prevent="">Scoring and Performance</a>
        </div>

        <div class="breadcrumb-item">Manage Players</div>

      </div>

    </div>

    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <app-form @show="show" :setPlayer="player" @toggleForm="toggleForm" :showForm="showForm"
                    @playingCountryChange="playingCountryChange"
                    @emptyPlayerObject="emptyPlayerObject" :countries="countries" :teams="teams"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-table :players="players" @show="show" @setPlayer="setPlayer" @search="search"
                     :loadingPlayers="loadingPlayers"/>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Form from "@/components/scoringAndPerformance/managePlayers/players/Form";
import Table from '@/components/scoringAndPerformance/managePlayers/players/Table';
import {search} from '@/services/managePlayers/Players';
import {useToast} from 'vue-toastification';

export default {
  components: {
    appForm: Form,
    appTable: Table,
  },
  data() {
    return {
      countries: [],
      teams: [],
      players: [],
      player: {},
      showForm: false,
      toast: useToast(),
      loadingPlayers: true,
    }
  },

  methods: {

    playingCountryChange(id) {
      this.player.playing_for_country = id;
    },
    show(data) {
      this.loadingPlayers = false;
      this.players = data.players;
      this.countries = data.countries;
      this.teams = data.teams;
    },

    setPlayer(player) {

      this.loadingPlayers = false;

      //show the form
      player.id ? this.showForm = true : this.showForm = false;

      for (let key in player) {
        this.player[key] = player[key];
      }



    },

    toggleForm() {


      //toggle the form
      this.showForm = !this.showForm;

    },

    emptyPlayerObject() {
      this.player = {};
      console.log(this.player);
    },

    search(seek) {
      this.toast.info('processing...')
      this.loadingPlayers = true;
      search(seek).then(response => {
        this.players = response;
        this.toast.clear();
        this.loadingPlayers = false;
      }, error => {
        this.toast.error(error.response.data.message);
      });
    },

  }
}
</script>
<style>

</style>